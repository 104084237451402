@import '../../../../assets/styles/_variables';

 .section {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
 }

.title {
	z-index: 1;
	position: absolute;
	top: 16px;
	width: 100%;
	font-size: 15px;
	line-height: 23px;
	font-weight: 400;
	text-align: center;
	color: $color-grey1;
}

.document {
	height: 204px;
}

.button {
	width: 190px;
}