@import '../../../../../assets/styles/variables';

@keyframes spin {
	from {
		transform: translateY(-50%) rotate(0deg);
	}
	
	to {
		transform: translateY(-50%) rotate(360deg);
	}
}

.group {
	margin-bottom: 16px;
}

.label {
	position: relative;
}

.input {
	padding-right: 42px;
}

.spin {
	position: absolute;
	right: 12px;
	top: 50%;
	animation: spin 1s infinite linear;
}

.scroll {
	max-height: calc(100vh - 310px);
	margin-top: 24px;
}

.error {
	margin: 24px 0 0;
	text-align: center;
	color: $color-grey1;
}
