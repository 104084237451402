@import '../../../../../../../../assets/styles/variables';

.table {
	background-color: #fff;
	padding: 0 20px;
	flex: 1;
}

.row {
	&:hover {
		.actions {
			display: flex;
		}
	}

	&:last-child > td {
		border: none !important;
	}

	& > td,
	& > th {
		vertical-align: top;
		background-color: #fff !important;

		&:first-child {
			padding-left: 0 !important;
		}

		&:last-child {
			padding-right: 0 !important;
		}
	}

	td {
		cursor: pointer;
	}

	&__head th {
		background-color: #fff !important;
		border: none !important;

		&::before {
			display: none;
		}
	}
}

.status__space {
	display: flex;
	align-self: flex-start;
	gap: 32px;

	span {
		height: fit-content;
	}
}

.icon__color {
	svg	{
		fill: transparent;
	}
}

.row_pointer {
	cursor: pointer;
}