@import '../../../../assets/styles/_variables';

.modal  > [class='ant-modal-content'] {
	padding: 32px 32px 24px;
}

.title {
	margin-bottom: 24px;
	font-size: 17px;
	line-height: 26px;
}

.body {
	margin: 0 0 24px;
	color: $color-grey1;
}

.button {
	margin: 0 0 0 auto;
}