@import '../../../../../../../../../assets/styles/_variables';

.wrap {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 24px;
}

.number {
	width: 100%;
	margin: 0 0 8px;
	font-weight: 500;
	line-height: 22px;
}

.name {
	flex-grow: 1;
}

.button {
	margin-left: 8px;
	color: $color-black;
	background-color: $color-grey4;
	box-shadow: none;
	
	&__yes {
		color: $color-white;
		background-color: $color-green;
	}

	&__no {
		color: $color-white;
		background-color: $color-grey1;
	}
}

.switch {
	display: none;
}