@import '../../../../../../../../../../../assets/styles/variables';

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	margin: 16px 24px;
}

.button__code {
	padding: 0;
	line-height: 22px;
	border: none;
	color: $color-blue3 !important;
}


.buttons {
	display: flex;
	justify-content: space-between;
}

.not_found {
	width: 100%;
	background: $color-red3;
	border: 1px solid $color-red4;
	padding: 8px 16px;
	color: $color-red;
}


.counter {
	font-size: 48px;
	line-height: 56px;
	font-weight: 500;
	color: $color-grey2;
	margin-left: auto;

	&__current {
		font-size: 64px;
		line-height: 75px;
		color: $color-black;

		span {
			font-size: 48px;
			line-height: 56px;
		}
	}

	&__surplus {
		color: $color-yellow;
	}

	&__error {
		color: $color-red;
	}
}

.modal {
	[class*='ant-modal-header'] {
		display: none;
	}

	&__body {
		padding: 32px 32px 24px;
	}

	&__title {
		margin-bottom: 8px;
		font-size: 16px;
		line-height: 24px;
	}

	[class*='ant-modal-footer'] {
		padding: 0 32px 24px;
	}
}

.input {
	margin-top: 24px;

	div, input {
		width: 100%;
	}
}

.checkbox {
	display: flex;
	align-items: center;
	margin-top: 16px;
	line-height: 22px;
}

.error {
	margin: 16px 0 0;
	padding: 8px 16px;
	font-size: 15px;
	line-height: 22px;
	color: $color-red;
	background-color: $color-red3;
	border: 1px solid $color-red4;
}

.popover {
	[class='ant-popover-inner'] {
		padding: 0;
	}

	&_content {
		width: 100%;

		div {
			padding: 8px 16px;
			transition: .3s;
			cursor: pointer;
			
			&:hover {
				background: $color-blue2;
				color: $color-blue;
			}
		}
	}
}
