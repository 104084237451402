@import '../../../../../../../../../assets/styles/variables';

.title {
	color: $color-blue;
}

.select {
	height: 22px;

	[class='ant-select-arrow'] {
		transition: transform 0.3s;
	}

	&[class*='ant-select-open'] {
		[class='ant-select-arrow'] {
			transform: rotate(180deg);
		}
	}

	[class='ant-select-selector'] {
		border: none !important;
		box-shadow: none !important;
		background-color: inherit !important;
		padding-left: 0 !important;
	}

	[class='ant-select-selection-item'] {
		color: $color-grey1 !important;
	}
}

.list {
	min-width: 300px;
}
