.modal {
	[class*='ant-modal-header'] {
		display: none;
	}
}

.body {
	padding: 32px 32px 24px;
}

.title {
	margin-bottom: 24px;
	font-size: 16px;
	line-height: 24px;
}

.description {
	margin: 0;
}

.button {
	margin: 0 auto;
}
