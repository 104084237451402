@import '../../../../../../../assets/styles/variables';

.table {
	padding-left: 24px;
	padding-right: 16px;
	background-color: #fff;
}

.row {
	cursor: pointer;

	&:last-child > td {
		border: none !important;
	}

	& > td,
	& > th {
		vertical-align: top;
		background-color: #fff !important;

		&:first-child {
			padding-left: 0 !important;
		}

		&:last-child {
			padding-right: 0 !important;
		}
	}

	&__head {
		cursor: unset;
		
		th {
			background-color: #fff !important;
			border: none !important;

			&::before {
				display: none;
			}
		}
	}

	&:hover .actions {
		opacity: 1;
	}
}

.status {
	color: $color-grey1;
}

.actions {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	opacity: 0;
	transition: opacity 0.2s;
}
