.actions {
  display: flex;
  opacity: 0;
  transition: opacity 0.2s;
  justify-content: flex-end;
  gap: 8px;

  &__visible {
	opacity: 1;
  }

  tr:hover & {
    opacity: 1;
  }
}
