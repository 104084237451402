@import '../../../../../../../assets/styles/variables';

.wrap {
	position: relative;
	display: flex;
	flex: 1 1;
	padding: 0;
	min-height: 150px;
	background-color: #fff;
}

.title {
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: 1px solid $color-grey3;

	&__text {
		font-size: 15px;
		line-height: 23px;
		font-weight: 400;
		transform: rotate(270deg);
		white-space: nowrap;
	}
}

.scroll {
	padding: 0 16px;
	flex: 1;
}

.tag {
	background: $color-white;
	margin-bottom: 4px;
}

.table {
	width: 100%;
	border-collapse: collapse;

	p {
		margin: 0 0 4px;
	}
}

.row {
	vertical-align: top;
	cursor: pointer;
	border-bottom: 1px solid $color-grey3;
}

.cell {
	padding: 16px;

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}

	tr:hover & {
		background: none !important;
	}
}

.info {
	min-width: 120px;
}

.clear {
	position: absolute;
	bottom: 12px;
	right: 16px;
	padding: 6px 15px;
	color: $color-grey1;
	background-color: #F0F2F5;
	border-color: #F0F2F5;
	pointer-events: all;
}

.tags {
	flex: 1;

	&__wrap {
		display: flex;
		flex-grow: 1;
	}

	&__other {
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
		gap: 16px;
	}
}

.tag {
	margin-bottom: 4px;
	background-color: #fff;

	&__postal,
	&__order:last-child {
		margin-right: 48px;
	}
}

.tooltip__card {
	cursor: auto;
}

.other__payment {
	padding-right: 32px;
}

.bold {
	font-weight: 500;
}

.adult_mark {
	color: $color-red5;
}

.container {
	display: flex;
	align-items: flex-start;
	column-gap: 12px;
	padding: 0 10px;
}

.button {
	margin: 0 auto;
}
