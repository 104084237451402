.cargo_spaces {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  height: 100%;
}

.no__data {
  width: 100%;
}
