.header {
	z-index: 1;
	height: 192px;
	padding: 22px 24px 24px;
	line-height: 0;
	background: #fff;
	box-shadow: 0px 2px 8px 8px rgba(0, 0, 0, 0.1);

	&__no_shadow {
		box-shadow: none;
	}
}

.scroll {
	height: 100%;
	max-height: calc(100vh - 192px - 48px);
	padding: 24px;

	&_wrap {
		height: 100%;
	}
}
