@import '../../../../../../../../assets/styles/variables';

.scroll {
	box-sizing: border-box;
	max-height: 100%;
	padding-top: 8px;
	padding-right: 10px;
}

.switch {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 14px 12px;
	border: 1px solid $color-grey3;
	border-radius: 2px;
	
	&__list {
		width: 100%;
		margin: 14px 0 0;
		padding: 14px 0 0;
		border-top: 1px solid $color-grey4;
		list-style-type: none;
	}

	&__item:not(:last-child) {
		margin-bottom: 16px;
	}

	&__checkbox {
		display: flex;
		align-items: center;
	}

	.checkbox__text {
		display: flex;
		align-items: center;
		gap: 8px;
		line-height: 22px;
	}
}