.wrap {
	display: flex;
	justify-content: flex-end;
}

.button__icon {
	display: flex;
	align-items: center;
	
	.icon {
		margin-left: 4px !important;
	}
}