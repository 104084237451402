@import "../../../../../../../../assets/styles/variables";

.statistics {
  padding: 16px 26px;
  background: $color-white;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > span:first-child {
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-grey4;
  }
}

.counter {
  font-size: 48px;
  line-height: 56px;
  font-weight: 500;
  color: $color-grey2;
  height: 180px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  position: relative;

  & > span:first-child {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    background: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__current {
    font-size: 64px;
    line-height: 75px;
    color: $color-black;

    span {
      font-size: 48px;
      line-height: 56px;
      color: $color-red;
    }
  }
}

.percent {
  font-size: 38px;
  font-weight: 500;
  margin: 16px 0;
  line-height: 46px;
  color: $color-green;
}