.option {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  padding: 11px 0;
}

.icon {
	margin-right: 8px;
}

.number {
  min-width: 145px;
}

.id {
  display: block;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.phone {
  flex-basis: 162px;
  min-width: 162px;
}

.name {
  flex: 1;
  max-width: calc(100% - 175px - 162px);
  
  span {
	overflow: hidden;
	text-overflow: ellipsis;
  }
}

.phone, .name {
  display: flex;
  flex-direction: column;
}
