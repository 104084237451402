@import '../../assets/styles/variables';

.message {
	min-height: 500px;
	height: 100%;
	height: 100vh;
	font-size: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-red;

	&__clearable {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-red;
		background-color: #fff;
		z-index: 999999;
	}
}

.main {
  height: 100vh;

  li[class~="ant-menu-item-selected"] {
    svg {
      [class="stroke"] {
        stroke: $color-blue;
      }
    }
  }

  li.menu_item {
    width: 100%;
    margin: 0 0 8px;
    padding: 0 16px !important;

    &>span {
      padding: 10px 0;
      line-height: 20px;
      transition: none !important;
    }
  }
}

.icon {
  font-size: 20px !important;
}

.sider {
  z-index: 2;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

  & > *:first-child {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  }

  & > *:last-child {
    height: 41px;
  }

  .trigger {
    height: 41px;
  }
}

.trigger {
  display: flex;
  height: 100%;
  align-items: center;
  box-shadow: inset 0px 1px 0px #D9D9D9;
  padding-left: 16px;

  &_icon {
    width: 10px;
  }
}

.menu {
  border: none !important;
}

.modal__title {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	margin-top: 16px;
}

.modal__button {
	margin: 0 auto;
}

.modal__kassa {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
	padding-top: 0;

	&_footer {
		display: flex;
		justify-content: center;
		gap: 10px;
		padding-bottom: 8px;

		& > * {
			margin: 0 !important;
		}
	}

	&_title {
		font-size: 17px;
		line-height: 26px;
	}
}

.lottie {
	&__progress {
		width: 267px;
		height: 8px;
	}

	&__error {
		width: 72px;
		height: 64px;
	}

	&__critical {
		width: 72px;
		height: 72px;
	}
}

.holiday {
	position: absolute;
	left: 50%;
	bottom: 20px;
	transform: translate(-50%, -100%);
	filter: grayscale(1);

	&__on {
		filter: none;
	}
}