@import '../../../../assets/styles/_variables';

.list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.link {
	position: relative;
	display: flex;
	align-items: center;
	padding: 8px 16px;
	color: $color-black;

	&:hover {
		color: $color-blue;
		background-color: $color-blue5;
	}
}
