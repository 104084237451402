@import '../../../../../assets/styles/variables';

h4.title {
	margin-bottom: 16px;
	line-height: 28px;
}

.tabs {
	[class='ant-tabs-nav-wrap'] {
		overflow: unset !important;
	}
}

.tab {
	position: relative;

	&_button {
		position: absolute;
		padding: 0;
		height: auto;
		border: none;
		right: -16px;
		top: calc(50% - 6px);
		opacity: 0;
		box-shadow: none;
	}

	&:hover &_button {
		opacity: 1;
	}
}

.controls {
	display: flex;
	gap: 24px;
	margin-top: 24px;
	line-height: normal;

	.button {
		width: 48px;
		padding: 4px 15px;
	}
}

.left {
	display: flex;
	flex-grow: 5;
	gap: 8px;
	max-width: 1086px;
}

.space {
	& > * {
		min-width: 240px;

		&:first-child {
			display: flex;
			flex-grow: 1;
		}
	}
}

.clear {
	display: flex;
	justify-content: flex-end;
	flex-grow: 1;
	gap: 8px;
}

.doc {
	display: flex;
	align-items: center;
	gap: 8px;
	height: 32px;
	padding: 5px 10px;
	color: $color-green;
	background-color: $color-green2;
	border: 1px solid $color-green3;
	white-space: nowrap;

	&__red {
		color: $color-red;
		background-color: $color-red3;
		border: 1px solid $color-red2;
	}

	&__orange{
		color: #dc7915;
		background-color: #ffeede;
		border: 1px solid #d5a980;
	}
}
