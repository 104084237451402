@import '../../../../../../../assets/styles/variables';

.popover {
	width: 180px;

	[role='tooltip'] {
		padding: 0;
	}
}

.wrap {
	position: relative;
}

.button {
	width: 48px !important;

	& > * {
		margin: 0 !important;
	}
}

.clear {
	position: absolute;
	top: -6px;
	right: -4px;
	width: 16px !important;
	height: 16px !important;
	color: $color-grey2;
	border-radius: 50%;
}
