$color-blue: #1890FF;
$color-blue2: #E6F7FF;
$color-blue3: #096DD9;
$color-blue4: #40A9FF;
$color-blue5: rgba(24, 144, 255, 0.08);
$color-blue6: #E6F7FF;
$color-red: #FF4D4F;
$color-red2: #FFA39E;
$color-red3: #FFF1F0;
$color-red4: #FFCCC7;
$color-red5: #F5222D;
$color-black: #252525;
$color-header: #001529;
$color-grey1: #6B6B6B;
$color-grey2: #A3A3A3;
$color-grey3: #D9D9D9;
$color-grey4: #F2F2F2;
$color-grey5: #EBEBEB;
$color-background: #F0F2F5;
$color-white: #FFFFFF;
$color-yellow: #FADB14;
$color-yellow2: #FFE58F;
$color-yellow3: #FFFBE6;
$color-orange: #FAAD14;
$color-green: #52C41A;
$color-green2: #F6FFED;
$color-green3: #B7EB8F;
$color-green4: #93FF5E;
