.wrap__title {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 12px;
}

.title {
	font-weight: 500;

	&__edit {
		display: block;
		margin-bottom: 12px;
		font-weight: 500;
	}
}

.form {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
}

.name {
	margin-bottom: 8px;
}