@import '../../../../../../../../../assets/styles/_variables';

.list {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 0;
	padding: 12px;
	list-style-type: none;
}

.item {
	display: flex;
	justify-content: space-between;

	&:first-child {
		padding-bottom: 16px;
		border-bottom: 1px solid $color-grey4;
	}
}

.count {
	color: $color-grey2;
}
