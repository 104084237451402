.wrap__accept {
	margin-bottom: 56px;
}

.services {
	flex: 0 1 308px;

	@media (max-width: 1600px) {
		display: none;
	}
}