@import '../../../../../../../assets/styles/variables';

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
}

.scroll {
	max-height: calc(100vh - 300px);
}

.table {
	margin-top: 24px;
	&__caption {
		margin: 0;
	}

	[class='ant-table-footer'] {
		display: flex;
		justify-content: flex-end;
		padding: 16px 24px;
	}

	[class*='ant-table-selection-col'] {
		display: none;
	}
}

.row {
	&__head {
		background-color: $color-grey4 !important;
	}

	&:last-child {
		.cell {
			border-bottom: none;
		}
	}
}

[class*='ant-table-row-selected'] {
	.cell {
		background-color: $color-blue6 !important;
	}
}

.cell {
	padding: 8px !important;
	background-color: unset !important;
	vertical-align: top;
	text-align: left !important;

	&::before {
		content: none !important;
	}

	&__actions {
		text-align: right !important;

		& > *:not(:first-child) {
			margin-left: 8px;
		}
	}
}

.confirm {
	margin-top: -16px;
	margin-bottom: 25px;
	padding: 0 24px;
}
