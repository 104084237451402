@import '../../../../assets/styles/_variables';

.modal > [class='ant-modal-content'] {
	padding: 32px 32px 24px;
}

.title {
	margin-bottom: 24px;
	font-size: 17px;
	line-height: 26px;
}

.body {
	margin-bottom: 24px;
}

.description {
	margin: 0 0 16px;
}

.select {
	margin-bottom: 16px;
}

.footer {
	display: flex;
	justify-content: flex-end;
}