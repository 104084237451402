@import '../../../../../assets/styles/variables';

.wrapper {
    padding: 16px;
    padding-right: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: $color-white;

    p {
        margin: 0;
    }
}

.wrapper__info {
    display: flex;
    gap: 40px;
}

.info {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.icon__acc {
    padding-top: 3px;
}

.status {
    color: $color-grey1;

    &__success{
        color: $color-blue;
    }
}

.text {
    font-weight: 500;
}

.error {
    color: $color-red;
}
