@import '../../../../../../../assets/styles/variables';

.search {
	width: 160px;
	margin-bottom: 4px;
}

.error {
	margin: 0;
	padding-left: 12px;
	color: $color-grey1;
}