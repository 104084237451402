@import '../../../../../../../assets/styles/variables';

.table {
	padding-left: 24px;
	padding-right: 16px;
	background-color: #fff;
}

.row {
	&:hover {
		.actions {
			display: flex;
		}
	}

	& > td,
	& > th {
		vertical-align: top;
		background-color: #fff !important;

		&:first-child {
			padding-left: 0 !important;
		}

		&:last-child {
			padding-right: 0 !important;
		}
	}

	&__head th {
		background-color: #fff !important;

		&::before {
			display: none;
		}
	}
}

.id {
	margin-left: 32px;
}

.tag {
	margin-inline-end: 4px;
	padding-inline: 8px;
	background-color: #fff;

	&__scan {
		background-color: $color-background;
	}
}

.actions {
	display: none;
	gap: 8px;
}
