@import '../../../../../../../../../assets/styles/variables';

.table {
	padding-left: 24px;
	padding-right: 16px;
	background-color: #fff;
}

.row {
	&:last-child > td {
		border: none !important;
	}

	& > td,
	& > th {
		vertical-align: top;
		background-color: #fff !important;

		&:first-child {
			padding-left: 0 !important;
		}

		&:last-child {
			padding-right: 0 !important;
		}
	}

	&__head th {
		background-color: #fff !important;
		border: none !important;

		&::before {
			display: none;
		}
	}
}

.status {
	color: #FA8C16;

	&__inner {
		color: $color-grey1;
	}
}

.button__not_good {
	width: fit-content;
	margin-left: auto;
}

.tag {
	margin-inline-end: 4px;
	padding-inline: 8px;
	background-color: #fff;
}
