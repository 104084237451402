.modal {
	& [class='ant-modal-footer'],
	& [class='ant-modal-header'] {
		padding: 0;
	}

	& [class='ant-modal-content'] {
		padding: 32px 32px 24px;
	}
}
