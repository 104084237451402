.body {
  display: flex;
  gap: 80px;
  margin: 0 24px;
}

.list {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 400px;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.item {
	display: flex;
	justify-content: space-between;
	gap: 24px;

	* {
		line-height: 22px;
	}

	& > *:first-child {
		flex-grow: 1;
	}
}
