@import '../../assets/styles/_variables';

.wrap {
	position: relative;
	
	* {
		background: #fff !important;
	}

	input {
		font-size: 15px;
		font-family: 'Roboto', sans-serif;
	}

	&__disabled input {
		color: $color-grey1 !important;
	}
}


.label {
	position: absolute;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	padding: 0;
	font-size: 15px;
	line-height: 22px;
	color: $color-grey2;
	background-color: unset;
	pointer-events: none;

	&__active {
		top: -7px;
		left: 8px;
		transform: none;
		font-size: 12px;
		line-height: 14px;
		padding: 0 4px;
		color: $color-grey1;
		transition: top .2s;

		&::before {
			content: "";
			position: absolute;
			height: 1px;
			background-color: $color-white;
			width: 100%;
			left: 0px;
			top: 7px;
		}
	}

	&__disabled {
		&::before {
			background-color: $color-background;
		}
	}
}

.asterisk {
	color: $color-red;
}