@import '../../../../../../../../../assets/styles/variables';

.modal {
	[class*='ant-modal-footer'] {
		padding: 10px 16px;
	}
}

.title {
	margin-bottom: 4px;
	font-size: 17px;
	line-height: 26px;
	font-weight: 500;
}

.description {
	margin: 0;
	font-size: 15px;
	line-height: 22px;
	font-weight: 400;
	color: $color-grey1;

	&__trouble {
		color: $color-red;
	}
}

.body {
	padding: 8px 0 24px 0;
	overflow-y: hidden;
	transition: height 0.3s;
	min-height: 230px;
}

.scroll {
	flex: 1;
	max-height: 500px;
}

.footer {
	display: flex;
	justify-content: flex-end;
}
