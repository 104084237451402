@import "../../../../../../../assets/styles/variables";

.modal {
  [class='ant-modal-header'] {
    margin-bottom: 0;
    padding-bottom: 16px;
    box-shadow: inset 0 -1px 0 #F0F0F0;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.footer {
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
  box-shadow: inset 0 2px 0 #F0F0F0;
}

.sum {
    padding: 16px 24px;
    background: $color-grey5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    margin-top: 24px;

    div {
      color: $color-grey1;
    }
}

.total_price {
  font-size: 24px;
  font-weight: 500;
  color: initial;
}

.table {
  margin-top: 24px;

  thead {
    th {
      border: none;
    }
  }

  td {
    border-bottom: none;
  }
}

.yellow {
  background: $color-yellow;
}

.green {
  background: $color-green;
}