.table {
  table {
    th::before {
      opacity: 0;
    }
    tr:hover td {
      background: none !important;
    }
  }

  *::-webkit-scrollbar {
    width: 4px;
  }

  *::-webkit-scrollbar-thumb {
    background: #a3a3a3;
    border-radius: 2px;
  }

  tr:hover {
    td {
      background: none !important;
    }
  }
}
