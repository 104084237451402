@import '../../../../../../../../assets/styles/variables';

.table {
	background-color: #fff;
	padding: 0 20px;

	thead {
		display: none;
	}
}

.actions {
	display: none;
}

.row {
	cursor: pointer;
	&:hover {
		.actions {
			display: flex;
			justify-content: flex-end;
		}
	}

	&:last-child > td {
		border: none !important;
	}

	& > td,
	& > th {
		vertical-align: top;
		background-color: #fff !important;

		&:first-child {
			padding-left: 0 !important;
		}

		&:last-child {
			padding-right: 0 !important;
		}
	}

	&__head th {
		background-color: #fff !important;
		border: none !important;

		&::before {
			display: none;
		}
	}
}

.status__space {
	display: flex;
	align-self: flex-start;
	gap: 8px;

	div {
		display: flex;
		flex-direction: column;

		span:last-child {
			color: $color-red;
		}
	}
}

.mail_icon {
	path {
		fill: black;
	}
}

.container__flex {
	display: flex;
	flex-direction: column;
}

.button {
	background: $color-red;
	color: $color-white;
	margin-left: auto;

	&:hover {
		background: $color-red;
		color: $color-white!important;
		border-color: $color-red!important;
		float: right;
	}
}


.no__data {
	width: 100%;
}