@import '../../../../../../../../../assets/styles/variables';

.modal {
	[class*='ant-modal-header'] {
		display: none;
	}

	[class*='ant-modal-footer'] {
		padding: 0 32px 32px;
	}
}

.body {
	padding: 32px 32px 24px;
}

.title {
	margin-bottom: 24px;
	font-size: 16px;
	line-height: 24px;
}

.description {
	margin: 0;
}

.input {
	margin-top: 24px;
}

.footer {
	display: flex;
	justify-content: flex-end;
}
