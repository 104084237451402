@import '../../../../../../../../../assets/styles/variables';

.wrap {
	display: flex;
	align-items: flex-start;
	gap: 48px;
	padding: 24px;
	
	& > * {
		flex: 1 0 calc(50% - 24px);
	}
}

.spaces {
	padding: 24px;
	background-color: #fff;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title {
	font-size: 17px;
	font-weight: 500;
	line-height: 26px;
}

.list {
	margin: 16px 0 24px;
	padding: 0;
	list-style-type: none;
}

.item {
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 20px 24px;
	border: 1px solid $color-grey3;
	border-radius: 4px;
	cursor: unset;

	&:not(&__lock):hover {
		border-color: $color-grey1;
	}

	&__clickable {
		cursor: pointer;
	}

	&__lock {
		background-color: $color-grey4;
		border-color: $color-grey4;
	}

	& + & {
		margin-top: 8px;
	}
	
	& > *:not(:first-child, :last-child) {
		flex-grow: 1;
		flex-basis: 220px;
	}

	&:hover {
		.action {
			opacity: .6;

			&:hover {
				opacity: 1;
			}
		}
	}
}

.icon {
	display: flex;
	width: 20px;
	margin-right: 8px;
}

.name {
	max-width: 176px;
	font-weight: 500;
}

.action {
	opacity: 0;
}

.button span{
	font-size: 14px;
	line-height: 22px;
}

.disabled {
	opacity: .7;
}
