@import '../../../../../../../../../../../assets/styles/variables';

.spaces {
	padding: 24px;
	background-color: #fff;
}

.title {
	font-size: 17px;
	font-weight: 500;
	line-height: 26px;
}

.list {
	margin: 16px 0 0;
	padding: 0;
	list-style-type: none;
}

.item {
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 20px 24px;
	border: 1px solid $color-grey3;
	border-radius: 4px;
	cursor: pointer;

	&:not(&__lock):hover {
		border-color: $color-grey1;
	}

	&__clickable {
		cursor: pointer;
	}

	&__lock {
		background-color: $color-grey4;
		border-color: $color-grey4;
	}

	& + & {
		margin-top: 8px;
	}
	
	& > *:not(:first-child, :last-child) {
		flex-basis: 220px;
	}

	&:hover {
		.action {
			opacity: .6;

			&:hover {
				opacity: 1;
			}
		}
	}
}

.icon {
	width: 20px;
	margin-right: 8px;
}

.name {
	flex-grow: 1;
	font-weight: 500;
}

.count {
	width: 240px;
}

.action {
	opacity: 0;
}

.button span{
	font-size: 14px;
	line-height: 22px;
}
