@import '../../../../../assets/styles/variables';

.modal {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 32px 24px;
	padding: 24px;

	&__confirm {
		flex-direction: column;
		gap: 24px;
		padding-top: 0;
	}
}

.text {
	flex-basis: 244px;
}

.title {
	margin-bottom: 4px;
	font-size: 20px;
	line-height: 24px;
	font-weight: 400;

	&__inner {
		display: block;
		font-size: 30px;
		line-height: 40px;
	}
}

.post {
	display: block;
	margin-bottom: 8px;
	color: $color-grey1;
}

.tag {
	display: inline-block;
	margin-bottom: 12px;
	padding: 5px 10px;
	background-color: $color-grey4;
	border: 1px solid $color-grey3;
}

.phone {
	display: block;
	font-weight: 500;
}

.qr {
	margin: 0 auto;
}

.footer {
	display: flex;
	justify-content: flex-end;
}

.title__confirm {
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
}

.button__confirm {
	align-self: flex-end;
}
