@import '../../../../../assets/styles/variables';

.title {
	margin-bottom: 16px;
	line-height: 28px;
}

.content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;

	& > *:first-child {
		flex-grow: 1;
	}
}

.tabs {
	flex-grow: 1;
}

.checkbox {
	display: flex;
	align-items: center;
	margin-left: 20px;
	line-height: 22px;
}

.tab__radio_button {
	[class*='ant-radio-button-wrapper-checked'] {
		.tab {
			&__badge {
				color: inherit;
				background-color: $color-blue2;
			}
		}
	}
}

.tab {
  display: flex;
  align-items: center;
  gap: 5px;

  &__badge {
    font-size: 12px;
    padding: 0 8px;
  	line-height: 18px;
    color: $color-grey1;
    background-color: $color-grey4;
    border-radius: 100px;

    [aria-selected='true'] & {
      color: inherit;
      background-color: $color-blue2;
    }

	.active {
		color: inherit;
		background-color: $color-blue2;
	}
  }

  &__new {
	display: flex;
	align-items: center;
	gap: 4px;

	&::after {
		position: relative;
		content: '';
		width: 6px;
		height: 6px;
		background-color: $color-red;
		border-radius: 50%;
	}
  }
}

.button__unidentified_cargo {
	transition: .3s;
	svg {
		transition: .3s;
	}

	&:hover {
		svg {
			stroke: $color-blue;
		}
	}

	&:active {
		svg {
			stroke: $color-blue3;
		}
	}
}

.actions {
	display: flex;
	justify-content: space-between;
	gap: 24px;
	margin-top: 16px;
}

.buttons {
	display: flex;
	align-items: center;
	gap: 16px;
}

.input {
	flex-grow: 1;
	
	div, input {
		width: 410px;
	}

	input {
		max-height: 32px;
		padding: 5px 12px !important;
	}
}
