.tooltip {
	[role='tooltip'] {
		padding: 0;
	}
}

.wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;

	& > *:hover {
		cursor: pointer;
	}
}