.header {
  background: #fff;
  padding: 22px 24px;
}

.content {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 24px !important;

	& > * {
		width: calc(50% - 12px);
	}
}

.modal {
	&__title {
		display: inline-block;
		padding: 16px 8px 0;
		padding-top: 16px;
		padding-left: 8px;
	}
	
	&__footer {
		display: flex;
		justify-content: flex-end;
		padding: 0 22px 16px;
	}
}
