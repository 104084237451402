@import "../../../../../../../../../assets/styles/variables";


.modal {
  [class="ant-modal-body"] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.icon {
  path {
    fill: $color-grey2;
  }
  &__red {
    path {
      fill: $color-red;
    }
  }
  &__green {
    path {
      fill: #00C1A2;
    }
  }
}

.heading {
  margin-top: 24px;
  font-size: 17px;
  font-weight: 500;
}

.title {
  margin-top: 16px;
  color: $color-grey1;

  &__red {
    color: $color-red;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 24px;
}