@import '../../../../../../../../../assets/styles/variables';

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	margin: 16px 24px;

	&__button {
		padding: 0;
		line-height: 22px;
		border: none;
		outline: none !important;
	
		&:hover {
			color: $color-blue3 !important;
		}
	}
}

.modal {
	[class*='ant-modal-header'] {
		display: none;
	}

	&__body {
		padding: 32px 32px 24px;
	}

	&__title {
		margin-bottom: 8px;
		font-size: 16px;
		line-height: 24px;
	}

	[class*='ant-modal-footer'] {
		padding: 0 32px 24px;
	}
}

.input {
	margin-top: 24px;

	div, input {
		width: 100%;
	}
}

.checkbox {
	display: flex;
	align-items: center;
	margin-top: 16px;
	line-height: 22px;
}

.error {
	margin: 16px 0 0;
	padding: 8px 16px;
	font-size: 15px;
	line-height: 22px;
	color: $color-red;
	background-color: $color-red3;
	border: 1px solid $color-red4;
}
