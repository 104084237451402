@import '../../../../assets/styles/_variables';

.content {
	display: flex;
	max-width: 400px;
	max-height: 400px;
	padding-right: 2px;
}

.scroll {
	padding: 8px 12px;
}

.text {
	margin: 0;
}

.panel {
	display: flex;

	* {
		padding: 0 !important;
	}

	[class='ant-collapse-header-text'] {
		display: flex;
	}
}

.button {
	display: inline-block;
	padding: 0;
	border: none;
	overflow: hidden;

	&__hide {
		display: none;
	}
}
