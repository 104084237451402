@import '../../../../../assets/styles/variables';

.title {
	margin-bottom: 16px;
	line-height: 28px;
}

.content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.tabs {
	flex-grow: 1;

	[role='tablist']::before {
		display: none;
	}
}

.checkbox {
	display: flex;
	align-items: center;
	margin-left: 20px;
	line-height: 22px;
}

.tab {
	display: flex;
	align-items: center;
	gap: 5px;

	&__badge {
		padding: 0 4px;
		font-size: 12px;
		line-height: 16px;
		color: $color-grey1;
		background-color: $color-grey4;
		border-radius: 50%;

		[aria-selected='true'] & {
			color: inherit;
			background-color: $color-blue2;
		}
	}

  &__new {
	display: flex;
	align-items: center;
	gap: 4px;

	&::after {
		position: relative;
		content: '';
		width: 6px;
		height: 6px;
		background-color: $color-red;
		border-radius: 50%;
	}
  }
}

.actions {
	display: flex;
	justify-content: space-between;
	margin-top: 16px;
}

.button {
	transition: none !important;
}
