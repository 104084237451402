@import '../../../assets/styles/_variables';

.button {
	display: flex;
	justify-content: center;
	align-items: center;
}

.text {
	color: $color-blue;

	&:not(:disabled):hover {
		color: #40A9FF;
	}

	&:not(:disabled):active {
		color: $color-blue3;
	}
}

.danger {
	:hover {
		border-color: $color-red;
	}

	&__text {
		&:hover,
		&:active {
			background-color: transparent;
			border-color: transparent;
		}
	}
}