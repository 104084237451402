@import '../../../assets/styles/variables';

.header {
	padding: 22px 24px 24px;
	height: 110px;
	line-height: 0;
	background: $color-white;
}

.title {
	margin-bottom: 16px;
	font-size: 20px;
	line-height: 28px;
	color: $color-black;
}

.description {
	margin: 0;
	line-height: 20px;
}

.content {
    margin: 24px;
	padding: 24px;
	background: $color-white;
	flex-grow: 0;
}