@import '../../../../../../../assets/styles/_variables.scss';

.modal {
	padding: 0;
}

.body {
	display: flex;
	flex-direction: column;
	row-gap: 24px;
	padding: 24px;
}

.warning {
	display: flex;
	gap: 12px;
	padding: 9px 16px;
	border: 1px solid $color-yellow2;
	background-color: $color-yellow3;
	border-radius: 6px;

	p {
		margin: 0;
	}

	strong {
		font-weight: 400;
	}
}

.footer {
	display: flex;
	gap: 8px;
	flex-wrap: nowrap;
	justify-content: flex-end;
	padding: 10px 16px;
	box-shadow: inset 0 1px 0 #f0f0f0;
}
