@import '../../../../../../../../../../assets/styles/variables';

.item {
  position: relative;
  display: flex;
  height: 46px;
  align-items: center;
  gap: 13px;

  &:not(&:last-child) {
    border-bottom: 1px solid $color-grey3;
  }
}

.popver {
  position: absolute;
  left: 0;
}

.icon {
  color: $color-grey3;

  &:hover {
    color: $color-grey2;
  }
}
