.modal {
	padding: 0;
}

.wrap {
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 200px);

}

.packs {
	padding: 0 24px;
}
