@import '../../assets/styles/variables';

.wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.text {
	margin: 0;
	color: $color-grey1;
}