@import '../../../../../../../../../assets/styles/variables';

.container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
}

.list {
	display: flex;
	flex-direction: column;
	row-gap: 24px;
	margin: 0;
	padding: 5px 0;
	list-style-type: none;

	li {
		position: relative;
		padding-left: 28px;
	}
}

.number {
	position: absolute;
	top: 8px;
	left: 0;
}

.fields {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
}

.button {
	background-color: inherit;
	border: none;
	padding: 0;
	margin-top: 8px;
	color: $color-grey1;
}

.error {
	font-size: 12px !important;
	line-height: 14px !important;
}

.add_button {
	align-self: flex-start;
}

.scroll {
	height: 100%;
	max-height: 178px;
}
