@import "../../../../../../../../../assets/styles/variables";

.modal {
  [class*='ant-modal-header'] {
    display: none;
  }

  [class*='ant-modal-footer'] {
    display: none;
  }
}

.text {
  font-size: 17px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
}

.icon {
  display: flex;
  justify-content: center;

  svg {
    fill: $color-grey2;
  }
}

.button {
  margin: 24px auto;
  margin-bottom: 0;
}