.input {
  height: 38px;
  width: 100%;

  &__sub {
    height: 30px;
    width: 100%;

    & > div {
      width: auto;
    }
  }

  & > div {
    width: auto;
  }
}
