.menu_header {
  background: #fff;
  height: 192px;
  padding: 22px 24px 24px;
  line-height: 0;
}

.modal__title {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	margin-top: 16px;
}

.modal__button {
	margin: 0 auto;
}