.modal {
  [class='ant-modal-header'] {
    display: none;
  }

  [class='ant-modal-footer'] {
    box-shadow: none !important;
    padding-top: 0 !important;
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
  }

  [class='ant-modal-body'] {
    div {
      padding-bottom: 24px;
      font-size: 16px;
    }
  }
}

.buttons {
  display: flex;
}