@import '../../../../assets/styles/_variables';

.list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.link {
	position: relative;
	display: flex;
	align-items: center;
	padding: 8px 16px 8px 52px;
	color: $color-black;

	&:hover {
		color: $color-black;
		background-color: $color-grey4;
	}
}

.icon {
	position: absolute;
	left: 16px;
	top: 50%;
	transform: translateY(-50%);
}
