@import '../../../../../../../assets/styles/variables';

.body  {
	padding: 8px 16px 8px 24px;
}

.scroll {
	max-height: calc(100vh - 410px);
	flex: 1;
}

.table {
	background-color: #fff;
}

.row {
	cursor: pointer;

	&:last-child > td {
		border: none !important;
	}
	
	& > td {
		vertical-align: top;
		background-color: #fff !important;

		&:first-child {
			padding-left: 0 !important;
		}

		&:last-child {
			padding-right: 0 !important;
		}
	}
}

.info {
	display: flex;
	align-items: center;
	gap: 32px;
}

.actions {
  opacity: 0;
  transition: opacity 0.3s;

  tr:hover & {
    opacity: 1;
  }
}

.footer {
	display: flex;
	justify-content: flex-end;
}
