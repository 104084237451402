@import '../../../../../../../assets/styles/_variables';

.modal {
	& > [class='ant-modal-content'] {
		padding: 0;
	}

	[class='ant-modal-header'] {
		margin: 0;
		padding: 32px 32px 0;
	}

	[class='ant-modal-footer'] {
		margin: 0;
		padding: 0 32px 24px;
	}
}

.title {
	font-size: 17px;
	line-height: 26px;
}

.list {
  margin: 24px 32px 0;
  padding: 0;
  list-style-type: none;
}
