@import '../assets/styles/variables';

.icon {
	position: relative;

	&__mark::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 6px;
		height: 6px;
		background-color: $color-red;
		border-radius: 50%;
	}
}