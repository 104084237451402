@import '../../../../../../../assets/styles/_variables';

.modal > [class='ant-modal-content'] {
	padding: 96px 32px 24px;
	position: relative;

	p {
		margin: 24px 0 0;
		font-size: 15px;
		line-height: 22px;
		color: $color-grey1;
		text-align: center;
	}
}

.title {
	font-size: 17px;
	line-height: 26px;
	margin-top: 24px;
	text-align: center;
}

.footer {
	display: flex;
	gap: 10px;
	margin: 24px 0 0;
	justify-content: center;
}

.icon {
	width: 72px;
	height: 64px;
	position: absolute;
	top: 32px;
	left: 50%;
	transform: translateX(-50%);
}
