.confirm {
	[class*='ant-modal-header'] {
		display: none;
	}

	&__body {
		padding: 32px 32px 24px;
	}

	&__title {
		margin-bottom: 24px;
		font-size: 16px;
		line-height: 24px;
	}

	[class*='ant-modal-footer'] {
		padding: 0 32px 24px;
	}
}
