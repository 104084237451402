@import '../../../../../../../../../assets/styles/_variables';

.modal [class*='ant-modal-footer'] {
	padding: 10px 16px;
}

.description {
	display: block;
	font-size: 15px;
	line-height: 22px;
	font-weight: 400;
	color: $color-grey1;
}

.document {
	display: flex;
	justify-content: center;
	height: 256px;
}

.footer {
	display: flex;
	justify-content: flex-end;
}
