.wrapper {
    display: flex;
    row-gap: 24px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 27px;
}

.input {
    flex-basis: 328px;
}