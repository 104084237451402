@import '../../../../assets/styles/variables';

.wrap {
	display: flex;
	flex-direction: column;
	gap: 8px;

	&:first-of-type {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -40px;
			width: 1px;
			height: 100%;
			background-color: $color-grey4;
		}
	}

	* {
		font-size: 15px;
		line-height: 22px;
	}

	p {
		margin: 0;
	}
}