@import "../../../../../../../assets/styles/variables";

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.table {
  max-height: 400px;
  overflow: auto;
}

.row {
  &__check {
    background-color: #93FF5E;
  }
}