.body {
  display: flex;
  align-items: flex-start;
  gap: 16px;

  & > * {
    flex-basis: 270px;

    &:first-child {
      flex-grow: 1;

      &:not(:last-child) > * {
        max-width: 400px;
      }
    }
  }
}
