@import "../../../../../assets/styles/variables";

.button {
	transition: .3s;
	&:hover {
		.icon {
			stroke: $color-blue;
		}
	}
}

.icon {
	transition: .3s;
	stroke: $color-black;
}