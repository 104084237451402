.modal {
	[class*='ant-modal-footer'] {
		padding: 10px 16px;
	}
}

.body {
	padding: 8px 0 24px 0;
	overflow-y: hidden;
	transition: height 0.3s;
	min-height: 230px;
}

.scroll {
	flex: 1;
	max-height: 500px;
}

.confirm {
	[class*='ant-modal-header'] {
		display: none;
	}

	&__body {
		padding: 32px 32px 24px;
	}

	&__title {
		margin-bottom: 8px;
		font-size: 16px;
		line-height: 24px;
	}

	&__text {
		margin: 0;
	}

	[class*='ant-modal-footer'] {
		padding: 0 32px 32px;
	}
}
