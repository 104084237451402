@import './assets/styles/variables';

/* Roboto Regular */
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src:  url('./assets/fonts/roboto/Roboto-Regular.woff') format('woff'),
        url('./assets/fonts/roboto/Roboto-Regular.woff2') format('woff2'),
        url('./assets/fonts/roboto/Roboto-Regular.ttf') format('ttf'),
}

/* Roboto Medium */
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src:  url('./assets/fonts/roboto/Roboto-Medium.woff') format('woff'),
        url('./assets/fonts/roboto/Roboto-Medium.woff2') format('woff2'),
        url('./assets/fonts/roboto/Roboto-Medium.ttf') format('ttf'),
}

/* Roboto Bold */
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src:  url('./assets/fonts/roboto/Roboto-Bold.woff') format('woff'),
        url('./assets/fonts/roboto/Roboto-Bold.woff2') format('woff2'),
        url('./assets/fonts/roboto/Roboto-Bold.ttf') format('ttf'),
}

body {
  margin: 0;
  font-family: 'Roboto';
  font-size: 15px;
  line-height: 22px;
  color: #252525;
}

h1 {
  font-size: 38px;
  line-height: 46px;
}

h2 {
  font-size: 30px;
  line-height: 40px;
}

h3 {
  font-size: 24px;
  line-height: 32px;
}

h4 {
  font-size: 20px;
  line-height: 28px;
}

h5 {
  font-size: 17px;
  line-height: 26px;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: 500;
}

b {
  font-weight: 500;
}

.rc-virtual-list-scrollbar {
  width: 4px !important;
  right: 4px !important;

  &-thumb {
    background-color: $color-grey2 !important;
  }
}

.os-scrollbar {
  width: 8px !important;

  &-handle {
    background-color: $color-grey2 !important;
  }
}

.ant-select {
  &-item-option-selected {
    font-weight: 400 !important;
    background-color: $color-grey4 !important;
  }

  &-open &-selection-item {
    color: $color-black !important;
  }
}

.messages {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-right: 16px;

	&-notice-content {
		position: relative;
		width: 320px;
	}
}

.message {
	box-sizing: border-box;
	top: 64px;
	max-width: 350px;
	padding: 10px 16px;
	text-align: left !important;

	&__scroll {
		max-width: 250px;
		max-height: 680px;
		margin-bottom: 10px;
		word-break: break-word;
	}

	&__close {
		position: absolute !important;
		top: 5px;
		right: 5px;
	}

	& > * > * {
		display: flex;
		align-items: center;
	}
}

.search-item {
	color: #fff;
	background-color: $color-blue;

	& > *:first-child {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			width: 16px;
			height: 100%;
			background-color: $color-blue;
		}
		
		&::before {
			left: -16px;
		}

	}

	& > *:last-child {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			width: 16px;
			height: 100%;
			background-color: $color-blue;
		}
		
		&::after {
			right: -16px;
		}
	}
}
