@import "../../../../../../../../../assets/styles/variables";

.modal {
	[class*='ant-modal-footer'] {
		padding: 10px 16px;
	}
}

.body {
	padding: 8px 0 24px 0;
	overflow-y: hidden;
	transition: height 0.3s;
	min-height: 230px;
}

.scroll {
	flex: 1;
	max-height: 500px;
}

.title {
	display: flex;
	flex-direction: column;

	span:last-child {
		font-size: 15px;
		font-weight: 400;
		color: $color-grey1;
	}
}

.modal_confirm {
	[class="ant-modal-header"] {
		display: none;
	}

	[class*='ant-modal-footer'] {
		display: none;
	}

	[class="ant-modal-close-x"] {
		display: none;
	}
}

.text {
	display: inline-block;
	font-weight: 500;
}

.buttons {
	margin-top: 24px;
	display: flex;
	justify-content: flex-end;
	gap: 8px;
}
