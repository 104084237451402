@import '../../assets/styles/_variables';

.wrapper {
  padding: 9px 12px;
  position: relative;
  background: $color-white;
  border: 1px solid $color-grey3;
  border-radius: 4px;
  display: flex;
  gap: 12px;
}

.label {
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  top: -10px;
  padding: 4px;
  background: $color-white;
  color: $color-grey1;
}

.wrapper__input {
  justify-content: flex-start;
  gap: 8px;

  input {
    height: auto !important;
  }
}

.prefix__text {
  font-size: 15px;
  line-height: 22px;
  color: $color-grey2;
  font-weight: 400;
}

.input {
  font-size: 15px;
  line-height: 22px;
  padding: 0;
  width: 16px;
  height: 22px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid $color-grey3;
  font-weight: 400;

  &:focus {
    box-shadow: none;
  }
}