@import '../../../../../../../../../assets/styles/variables';

.date__picker {
  [class*="ant-picker"] {
    border-radius: 4px;
  }
}

.form {
  margin-top: 24px;

  .input {
    [class^='ant-input'] {
      width: 100%;
    }
  }
}

.weight {
  display: flex;
  gap: 20px;
}

.error {
  padding: 8px 16px;
  border: 1px solid $color-red4;
  background: #faebeb;
  color: $color-red;
}