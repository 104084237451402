@import '../../../../../../../assets/styles/_variables';

.summary {
	display: flex;
	margin-bottom: 40px;
}

.item__wrap {
	width: 135px;

	&:last-child {
		flex-grow: 1;
	}
}

.name {
	margin-bottom: 16px;
	font-size: 14px;
	line-height: 22px;
	color: $color-grey1;
}

.value {
	display: flex;
	align-items: center;
	gap: 8px;
}

.divider {
	margin: 0 32px;
	
	& > * {
		height: 100%;
	}
}
