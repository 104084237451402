@import '../../../../../../../../../assets/styles/variables';

.table {
	thead {
		& * {
			background-color: #fff !important;

			&::before {
				display: none;
			}
		}
		& > *:first-child > * {
			padding-left: 24px;
		}

		& > *:last-child > * {
			padding: 1px 28px 1px 4px;
		}
	}
}

.row {
	background-color: $color-grey4;

	td {
		background-color: inherit !important;
	}

	&__check {
		background-color: #93FF5E;
	}

	&__new {
		background-color: #FFEF5E;
	}
	
	&__error {
		background-color: $color-red3;
	}
		
	&__expand {
		td {
			padding: 7px 52px 7px 24px !important;
			background-color: $color-red3 !important;
		}
	}
}

.cell {
	padding: 7px 0 !important;
	line-height: 22px;
	border: none !important;

	&:first-child > * {
		padding-left: 24px;
	}

	&:last-child > * {
		padding: 1px 28px 1px 4px;
	}
}

.counter {
	width: 100px;

	&, input {
		border-color: $color-black;
	}

	input:focus {
		background-color: #fff;
	}

	&, input:not(:hover, :focus, :active) {
		background-color: transparent;
		border-color: transparent;
	}

	&__disabled {
		color: $color-black;
		border-color: transparent !important;
	}
}

.error {
	display: inline-block;
	color: $color-red;
}
