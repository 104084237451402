@import "../../../../../../../../../assets/styles/variables";

.modal {
  [class="ant-modal-header"] {
    padding: 16px 20px;
    box-shadow: inset 0 -1px 0 #F0F0F0;
    margin-bottom: 16px;
  }

  [class='ant-modal-content'] {
    padding: 0;
  }
}

.modal__manual {
  [class='ant-modal-content'] {
    padding: 24px;
  }

  [class='ant-modal-footer'] {
    display: none;
  }

  [class='ant-modal-body'] {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      & > div {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.input_manual {
  width: 100%;
}

.button__manual {
  align-self: flex-end;
}

.title {
  font-size: 15px;
  color: $color-grey1;
  font-weight: 400;
  margin-top: 4px;

  &__green {
    color: $color-green;
  }
}

.body {
  min-height: 304px;
  & > div:nth-child(2){
    margin-top: 20px;
    padding: 0 20px;
    font-weight: 500;
  }
}

.list {
  margin-top: 8px;
  span {
    display: block;
    padding: 8px 20px;
    background: $color-grey4;
  }
}

.button {
  padding: 0 20px;
  line-height: 22px;
  border: none;
  color: $color-blue;

  &:hover {
    background: transparent!important;
    color: $color-blue3 !important;
  }
}

.footer {
  padding: 10px 20px;
  box-shadow: inset 0 1px 0 #F0F0F0;
}