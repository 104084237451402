@import '../../../../../../../../assets/styles/variables';

.options {
  [aria-selected="true"] {
    // background-color: $color-grey4 !important;
    font-weight: 400 !important;
  }
}

.select{
  [class="ant-select-selector"]{
    border-color: $color-grey3 !important;
    box-shadow: none !important;
  }
}
.input {
	margin: 8px;
}

.error {
	font-size: 12px;
	color: $color-red;
}
