@import '../../../../../../../assets/styles/variables';

.wrapper {
	display: flex;
	gap: 12px;
}

.input {
	width: 596px;

	* {
		max-height: 32px;
	}
}