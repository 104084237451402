@import '../../../../assets/styles/_variables';

.header {
	height: 48px;
	display: flex;
	align-items: center;
	gap: 24px;
	padding: 0 24px;

	&__info {
		flex-grow: 1;
		margin: 0;
		line-height: 22px;
		color: $color-white;
	}

	&__break {
		margin: 0;
		line-height: 22px;
		color: $color-white;
	}

	.account {
		position: relative;
		margin-right: 0;
		margin-left: auto;
		line-height: 20px;
		color: $color-white;
		cursor: pointer;
		padding-left: 20px;

		&::before {
			content: '';
			position: absolute;
			width: 8px;
			height: 8px;
			left: 0px;
			top: 50%;
			transform: translateY(-50%);
			background-color: $color-yellow;
			border-radius: 50%;
		}

		&_break::before {
			background-color: $color-orange;
		}

		&_closed::before {
			display: none;
		}
	}
}
