.body {
	padding-top: 8px;
	overflow-y: hidden;
	transition: height 0.3s;
	min-height: 230px;

	&__tracking {
		height: 512px;
	}
}
