@import '../../../../../../../assets/styles/variables';

.select {
	flex-grow: 1;
	
	input {
		font-family: 'Roboto', sans-serif;
		font-size: 15px;
	}
}

.error {
	margin: 20px 0;
	text-align: center;
	color: $color-grey1;
}