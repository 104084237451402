@import '../../../../../../../assets/styles/_variables';

.modal {
	[class*='ant-modal-footer'] {
		padding: 10px 16px;
	}
}

.description {
	display: block;
	margin-top: 4px;
	font-size: 15px;
	line-height: 22px;
	font-weight: 400;
	color: $color-grey1;
}

.body {
	padding: 8px 0 24px 0;
	overflow-y: hidden;
	transition: height 0.3s;
	min-height: 230px;
}

.footer {
	display: flex;
	justify-content: flex-end;
}

.tabs {
	margin-bottom: 24px;
	margin-left: 24px;

	[class*='ant-tabs-nav']::before {
		border: none;
	}
}
