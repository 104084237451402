@import '../../../../../../../../../assets/styles/variables';

.modal {
	[class*='ant-modal-header'] {
		display: none;
	}

	&__body {
		padding: 32px 32px 24px;
	}

	&__title {
		margin-bottom: 8px;
		font-size: 16px;
		line-height: 24px;
	}
	
	&__description {
		margin: 0;
	}

	[class*='ant-modal-footer'] {
		padding: 0 32px 32px;
	}
}
