@import '../../assets/styles/_variables';

.wrap {
	position: relative;
	margin-bottom: 0;
	transition: margin-bottom .1s;

	&__error {
		margin-bottom: 16px;
	}
}

.input:disabled {
	color: $color-grey1;
}

.input__phone+input {
	display: none !important;
}

.label {
	position: absolute;
	top: -7px;
	left: 8px;
	font-size: 12px;
	line-height: 14px;
	padding: 0 4px;
	color: $color-grey1;
	background-color: $color-white;
	transition: top .2s;

	&_placeholder {
		left: 12px;
		top: 50%;
		transform: translateY(-50%);
		padding: 0;
		font-size: 15px;
		line-height: 22px;
		color: $color-grey2;
		background-color: unset;
		pointer-events: none;
	}
}

.asterisk {
	color: $color-red;
}

.error {
	position: absolute;
	top: calc(100% + 1px);
	left: 0;
	font-size: 12px;
	color: $color-red;
}
