@import '../../../../../../../../../../../assets/styles/variables';

.header {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	margin: 16px 24px;

	&__button {
		padding: 0;
		line-height: 22px;
		border: none;
	
		&:hover {
			color: $color-blue3 !important;
		}
	}
}


.counter {
	font-size: 48px;
	line-height: 56px;
	font-weight: 500;
	color: $color-grey2;

	&__current {
		font-size: 64px;
		line-height: 75px;
		color: $color-black;

		span {
			font-size: 48px;
			line-height: 56px;
		}
	}
}
