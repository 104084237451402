@import '../../../../../../../../../assets/styles/variables';

.modal {
	[class*='ant-modal-footer'] {
		padding: 10px 16px;
	}
}

.body {
	padding: 8px 0 24px 0;
	overflow-y: hidden;
	transition: height 0.3s;
	min-height: 230px;
}

.footer {
	display: flex;
	justify-content: flex-end;
}

.tabs {
	margin-bottom: 24px;
	margin-left: 24px;

	[class*='ant-tabs-nav']::before {
		border: none;
	}
}

.modal {
	[class*='ant-modal-header'] {
		display: none;
	}

	&__body {
		padding: 32px 32px 24px;
	}

	&__title {
		margin-bottom: 8px;
		font-size: 16px;
		line-height: 24px;
	}

	[class*='ant-modal-footer'] {
		padding: 0 32px 24px;
	}
}

.inputs {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 24px 8px;
	margin-top: 24px;

	& > *:first-child {
		width: 238px;
		flex-grow: 1;
	}
}

.input {
	width: 100%;
	
	div, input {
		width: 100%;
	}
}

.error {
	margin: 24px 0 0;
	padding: 8px 16px;
	font-size: 15px;
	line-height: 22px;
	color: $color-red;
	background-color: $color-red3;
	border: 1px solid $color-red4;
}

.info {
	[class*='ant-modal-header'] {
		display: none;
	}

	&__body {
		padding: 32px 32px 24px;
	}

	&__icon {
		display: block;
		margin-bottom: 24px;
	}

	&__title {
		margin-bottom: 4px;
		font-size: 17px;
		line-height: 26px;
	}

	&__item {
		margin: 0 0 12px;
		color: $color-grey1;
	}

	&__warn {
		margin: 0 0 24px;
		color: $color-red;
	}

	&__button{
		margin: 0 auto;
	}
}
