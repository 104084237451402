@import '../../assets/styles/_variables';

.wrap {
	position: relative;
}

.input:disabled {
	color: $color-grey1;
}

.input:placeholder-shown + label {
	left: 12px;
	top: 8px;
	padding: 0;
	font-size: 15px;
	line-height: 22px;
	color: $color-grey2;
	background-color: unset;
	pointer-events: none;
}

.input__phone + input {
	display: none !important;
}


.label {
	position: absolute;
	top: -7px;
	left: 8px;
	font-size: 12px;
	line-height: 14px;
	padding: 0 4px;
	color: $color-grey1;
	background-color: $color-white;
	transition: top .2s;
}

.asterisk {
	color: $color-red;
}