@import '../../../../assets/styles/_variables';

.wrap {
	width: 460px;
	height: 226px;
	padding: 32px 24px 24px;
	background-color: $color-white;	
}

.description {
	margin: 0 0 24px;
	text-align: center;
}

.button {
	margin: 24px auto 0;
}
