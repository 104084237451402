@import '../../../../../assets/styles/variables';

.modal {
	[class*='ant-modal-header'] {
		display: none;
	}

	[class*='ant-modal-footer'] {
		padding: 0 32px 24px;
	}
}

.body {
	padding: 32px 32px 24px;
}

.title {
	margin-bottom: 24px;
	font-size: 16px;
	line-height: 24px;
}

.input {
	& > *:not(label) {
		width: 100%;
	}

	&__inner {
		margin-bottom: 16px;
	}
}

.error {
	font-size: 12px;
	color: $color-red;
}
