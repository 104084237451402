@import '../../../assets/styles/variables';

.header {
	z-index: 1;
	height: 72px;
	padding: 22px 24px 24px;
	line-height: 0;
	background: #fff;
	box-shadow: 0px 2px 8px 8px rgba(0, 0, 0, .1);

	&__no_shadow {
		box-shadow: none;
	}
}

.scroll {
	min-height: 100%;
	max-height: calc(100vh - 192px - 48px);
	padding: 24px;

	&_wrap {
		height: 100%;
	}
}

.qr {
	&__button {
		min-width: 48px;
		margin-left: auto;
		margin-right: 8px;
	}

	&__modal {
		display: flex;
		justify-content: center;
		height: 204px;
	}
}

.button {
	stroke: $color-black;
	
	&:not(:disabled):hover {
		stroke: #40A9FF;
	}

	&:not(:disabled):active {
		stroke: $color-blue3;
	}
}
