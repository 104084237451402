@import '../../../../../../../../src/assets/styles/_variables';

.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px;
	background-color: $color-white;
	min-height: 120px;

	&__error {
		padding-right: 0;
	}
}

.totals {
	display: flex;
	max-width: 1350px;
	margin: 0 16px 0 0;
	padding: 0;
	list-style-type: none;

	&__item {
		flex-grow: 1;
		display: flex;

		&:not(:first-child) {
			justify-content: center;
		}

		& > div {
			display: flex;
			flex-direction: column;
		}

		& + & {
			border-left: 1px solid $color-grey3;

			& > div {
				padding-left: 48px;
			}
		}

		&:not(:last-child) {
			& > div {
				padding-right: 48px;
			}
		}
	}
}

.title {
	margin-bottom: 16px;
	line-height: 22px;
	font-size: 15px;
	color: $color-grey1;
}

.value {
	line-height: 32px;
	font-size: 24px;
	font-weight: 500;

	&__inner {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}

.payment {
	& > div {
		flex-direction: row;
		gap: 24px;
	}

	&__wrap {
		display: flex;
		flex-direction: column;
	}

	&__label {
		margin-bottom: 8px;
	}

	&__input {
		border-color: $color-grey2;
		width: 120px;
	}
}

.confirm {
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	max-width: 285px;

	&__description {
		margin: 8px 0 0;
		font-size: 13px;
		line-height: 18px;
		color: $color-grey1;
	}
}

.messages {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-right: 16px;
}

.message {
	box-sizing: border-box;
	top: 64px;
	max-width: 336px;
	padding: 10px 16px;
	text-align: left !important;

	& > * > * {
		display: flex;
		align-items: flex-start;
	}

	span:first-child {
		margin-top: 4px;
	}
}

.scroll {
	max-height: 123px !important;
}

.error {
	margin: 10px 0;
	color: $color-red;
	font-size: 20px;
	text-transform: uppercase;
}
