@import '../../../../../../../../../assets/styles/variables';

.modal {
	[class*='ant-modal-footer'] {
		padding: 10px 16px;
	}
}

.body {
	margin: 40px 24px;
	padding: 0;
}

.goods {
	display: flex;
	align-items: center;
	gap: 16px;

	&__name {
		flex-grow: 1;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}

	&__count {
		width: 90px;
		
		&, input:not(:hover, :focus, :active) {
			border-color: transparent;
		}
	}

	&__del {
		opacity: .5;

		&:hover {
			opacity: 1;
		}
	}
}

.sign {
	margin-top: 24px;

	&__error input {
		border-color: $color-red;
	}

	div, input {
		width: 291px;
	}
}

.error {
	margin: 2px 0 0;
	font-size: 12px;
	line-height: 14px;
	color: $color-red;
}

.description {
	margin: 0;
	text-align: center;
}

.footer {
	display: flex;
	justify-content: flex-end;
}
