@import '../../../../../../../assets/styles/variables';

.modal {
	[class*='ant-modal-footer'] {
		padding: 10px 16px;
	}
}

.description {
	display: block;
	margin-top: 4px;
	font-size: 15px;
	line-height: 22px;
	font-weight: 400;
	color: $color-grey1;
}

.body {
	padding: 8px 0 24px 0;
	overflow-y: hidden;
	transition: height 0.3s;
	min-height: 230px;
}

.scroll {
	flex: 1;
	max-height: 500px;
}

.info {
	[class*='ant-modal-header'] {
		display: none;
	}

	&__body {
		padding: 32px 32px 24px;
	}

	&__icon {
		display: block;
		margin-bottom: 24px;
	}

	&__title {
		margin-bottom: 4px;
		font-size: 17px;
		line-height: 26px;
	}

	&__item {
		margin: 0 0 12px;
		color: $color-grey1;
	}

	&__warn {
		margin: 0 0 24px;
		color: $color-red;
	}

	&__button{
		margin: 0 auto;
	}
}
