@import '../../../../../../assets/styles/variables';

.wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;

    & > * {
		flex-grow: 1;
        max-width: 324px;
    }

    &__inspect {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 24px;
    }

    p {
        margin: 0;
    }
}

.phone {
    margin-bottom: 24px;
}

.status {
    color: $color-grey1;
    font-size: 12px;
}

.code {
    width: 100%;

    & > div:first-child {
        width: 100%;
    }

	&__message {
		font-size: 12px;
		color: $color-red;
	}
}