@import '../../../../../../../assets/styles/_variables';

.item {
	display: flex;
	gap: 32px;
	align-items: center;
	padding: 16px 0;
	font-weight: 500;
	line-height: 22px;
	cursor: pointer;
	transition: color .2s;

	&__active,
	&:hover {
		color: $color-blue;
	}

	& + & {
		border-top: 1px solid $color-grey4;
	}
}

.icon {
	margin-right: 8px;
}

.fio {
	flex-grow: 1;
	font-weight: 400;
}