@import '../../../../../../../../../../../assets/styles/variables';

.table {
	width: 100%;
	border-collapse: collapse;

	thead {
		background-color: #fff;
	}
}

.row {
	text-align: left;
	background-color: $color-grey4;

	&__check {
		background-color: #93FF5E;
	}

	&__new {
		background-color: #FFEF5E;
	}
}

.cell {
	padding: 3px 0;
	line-height: 22px;

	&:first-child {
		padding-left: 24px;
	}

	&:last-child {
		padding-right: 28px;
	}

	& > span {
		padding: 0 8px;
	}
}

.counter {
	width: 100px;

	&, input {
		border-color: $color-black;
	}

	input:focus {
		background-color: #fff;
	}

	&, input:not(:hover, :focus, :active) {
		background-color: transparent;
		border-color: transparent;
	}

	&__disabled {
		color: $color-black;
		border-color: transparent !important;
	}
}
