@import '../../../../../../../assets/styles/variables';

.search {
	width: 240px;
	margin-bottom: 4px;

	[class*='ant-input-number-handler-wrap'] {
		display: none !important;
	}
}

.error {
	margin: 0;
	padding-left: 12px;
	color: $color-grey1;
}