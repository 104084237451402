.modal {
	[class*='ant-modal-footer'] {
		padding: 10px 16px;
	}
}

.body {
	padding: 8px 0 24px 0;
	overflow-y: hidden;
	transition: height 0.3s;
	min-height: 230px;
}

.scroll {
	flex: 1;
	max-height: 500px;
}
