.table {
	margin: 0;
	padding: 0 24px;
	background: #fff;
	list-style: none;
}

.row {
	cursor: pointer;

	td:first-child {
		padding-left: 0 !important;
	}
	
	td:last-child {
		padding-right: 0 !important;
	}
}
