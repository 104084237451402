.header {
	z-index: 1;
	height: 112px;
	padding: 22px 24px 24px;
	line-height: 0;
	background: #fff;
	box-shadow: 0px 2px 8px 8px rgba(0, 0, 0, 0.1);

	&__inner {
		height: calc(112px + 64px);
	}

	&__no_shadow {
		box-shadow: none;
	}
}

.scroll {
	height: 100%;
	max-height: calc(100vh - 160px);
	padding: 24px;

	&__inner {
		max-height: calc(100vh - 160px - 48px);
	}

	&__wrap {
		height: 100%;
	}
}
