@import '../../../../../../assets/styles/variables';

.row {
	display: flex;
	gap: 16px;
	color: $color-grey1;
	align-items: center;

	& > div {
		width: 146px;
	}
}
