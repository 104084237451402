.wrap {
	padding: 24px;
	background: #fff;

	& > span {
		display: block;
		margin-bottom: 8px;
	}
}

.number {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 16px;
	font-size: 15px;
	line-height: 22px;
	font-weight: 500;
}

.name {
	margin: 0 0 16px;
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
}

.reason {
	margin: 0 0 32px;
}

.button {
	height: 38px;
	font-size: 16px;
}
