@import '../../../../../../../assets/styles/variables';

.table {
	table {
		margin-bottom: 24px;
	}

	&__caption {
		margin: 0 0 24px;
		line-height: 26px;
		font-size: 17px;
		font-weight: 500;
		text-align: left;
	}

	[class='ant-table-footer'] {
		display: flex;
		justify-content: flex-end;
		padding: 16px 24px;
	}

	[class*='ant-table-selection-col'] {
		display: none;
	}
}

.row {
	&__head {
		background-color: $color-grey4 !important;
	}

	&__warranty {
		background-color: $color-grey3 !important;
	}
}

[class*='ant-table-row-selected'] {
	.cell {
		background-color: $color-blue6 !important;
	}
}

.cell {
	min-width: 120px;
	padding: 8px !important;
	text-align-last: left;
	background-color: unset !important;

	&::before {
		content: none !important;
	}

	&__name {
		width: 100%;
	}
}

.error {
	display: flex;
	align-items: center;
	margin: 0 24px 0 0;
	color: $color-red;
}

.total {
	display: flex;
	align-items: center;
	gap: 12px;
	margin: 0;

	&__correction {
		margin-left: 32px;
		padding-left: 32px;
		border-left: 1px solid $color-grey3;
	}

	&__name {
		color: $color-grey1;
	}

	&__sum {
		line-height: 32px;
		font-size: 24px;
		font-weight: 500;
	}
}

.footer {
	display: flex;
	justify-content: flex-end;
}

.confirm {
	margin-top: -16px;
	margin-bottom: 25px;
	padding: 0 24px;
}

.correction_wrapper {
	display: flex;
	align-items: center;
	gap: 28px;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
