@import '../../assets/styles/variables';

.modal {
	transition: width 5s;
}

.body {
	display: flex;
	justify-content: center;

	&__inner {
		overflow-y: scroll;
		height: calc(100vh - 364px);
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
}

.description {
	display: block;
	max-width: 348px;
	margin-top: 4px;
	font-size: 15px;
	line-height: 22px;
	font-weight: 400;
	color: $color-grey1;
}

.wrap * {
	pointer-events: none;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.radio__buttons {
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-bottom: 20px;
}

.label {
	width: 100%;
	overflow: hidden;

	* {
		max-width: 957px;
	}

	canvas {
		width: 957px !important;
		height: auto !important;
	}

	@media print {
		&__thermal {
			[class='react-pdf__Page'] {
				* {
					max-width: 145mm !important;
				}
			}
		}
		[class='react-pdf__Page'] {
			z-index: 99999;
			position: fixed !important;
			top: 0;
			left: 0;
			width: 100vw !important;
			height: 100vh !important;

			* {
				max-width: 210mm;
				max-height: 297mm;
			}
		}

		canvas {
			width: unset !important;
			height: unset !important;
		}
	}
}

.title__warning {
	color: $color-red;
	display: block;
	font-weight: 400;
}
