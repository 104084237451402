@import '../../../../../assets/styles/variables';

.modal {
	padding: 0 32px 24px;
}

.icon {
    display: flex;
    justify-content: center;
	margin-bottom: 24px;
}

.button {
    margin: 0 auto;
}

.text {
	margin: 0;
    color: $color-black;
    font-size: 17px;
    text-align: center;
    font-weight: 500;
}