@import "../../../../../../../../../../../assets/styles/variables";

.confirm {
	[class*='ant-modal-header'] {
		display: none;
	}

	&__title {
		margin-bottom: 24px;
		font-size: 16px;
		line-height: 24px;
	}

	[class*='ant-modal-footer'] {
		display: none;
	}
}

.text {
	font-size: 17px;
	font-weight: 500;
	margin-top: 20px;
}

.icon {
	display: flex;
	justify-content: center;

	svg {
		fill: $color-grey2;
	}
}

.button {
	margin: 24px auto;
	margin-bottom: 0;
}