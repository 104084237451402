.table {
	padding-left: 24px;
	padding-right: 16px;
	background-color: #fff;
}

.row {
	& > td {
		vertical-align: top;

		&:first-child {
			padding-left: 0 !important;
		}

		&:last-child {
			padding-right: 0 !important;
		}
	}
}

.actions {
  opacity: 0;
  transition: opacity 0.3s;

  tr:hover & {
    opacity: 1;
  }
}
