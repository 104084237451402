@import '../../../../assets/styles/variables';

.scroll {
	max-height: calc(100vh - 410px);
	flex: 1;
}

.table {
	padding: 0 24px;
	width: 100%;
	border-collapse: collapse;
	
	p {
		margin: 0 0 4px;
	}

	&__inner {
		.row {
			background-color: $color-grey5;

			&__scan {
				background-color: $color-green4;
			}

			&__new {
				background-color: #FFEF5E;
			}
			
			&__error {
				background-color: $color-red3;
			}
		}
	}
}

.row {
	padding: 0 24px;
	vertical-align: top;

	&__expand {
		td {
			padding: 7px 52px 7px 24px !important;
			color: #F5222D;
			background-color: $color-red3 !important;
		}
	}

	&:not(:last-child, .row__error:not(.row__expand)) {
		border-bottom: .5px solid $color-grey3;
	}
}

.cell {
	padding: 16px 0;

	&:first-child {
		padding-left: 24px;
	}

	&:last-child {
		margin-right: 24px;
	}

	&__wrap {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}
