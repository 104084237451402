.item {
	display: flex;
	justify-content: space-between;
	align-items: center;

	& + & {
		margin-top: 16px;
	}

	& > *:last-child {
		flex: 0 1 345px;
	}
}
