.modal {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 24px 16px;
	padding: 24px;
	
	& > * {
		flex: 1 0 328px;
	}
}

.input__weight > *:first-child {
	width: 100%;
}

.checkbox {
	flex-basis: 100%;
}