@import "../../../../../../../../../assets/styles/variables";

.modal {
  [class='ant-modal-header'] {
    margin-bottom: 0;
    padding-bottom: 16px;
    box-shadow: inset 0 -1px 0 #F0F0F0;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.footer {
  padding: 5px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
}

.table {
  margin-top: 24px;

  thead {
    th {
      border: none;
    }
  }

  td {
    border-bottom: none;
  }
}

.icon {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.title {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 8px;
}