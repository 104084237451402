@import '../../../../../assets/styles/variables';

.header {
    background: $color-white;
    height: 136px;
    padding: 22px 24px 24px;
    line-height: 0;
}

.title {
    margin-bottom: 16px;
    line-height: 28px;
    color: $color-black;
    font-size: 20px;
}