.spinner {
  animation: 1s linear 0s normal none infinite running rot;
  position: absolute;
  right: 5px;
  top: 5px;
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
