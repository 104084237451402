@import '../../assets/styles/_variables';

.modal {
	& > [class='ant-modal-content'] {
		padding: 0;
	}

	[class='ant-modal-header'] {
		margin: 0 0 24px;
		padding: 16px 24px 0;
	}

	[class='ant-modal-footer'] {
		margin: 0;
		padding: 0 10px 16px;
	}

	&:not(.modal__no_lines) {
		[class='ant-modal-header'] {
			margin-bottom: 0;
			padding-bottom: 16px;
			box-shadow: inset 0 -1px 0 #F0F0F0;
		}
		[class='ant-modal-footer'] {
			padding-top: 10px;
			box-shadow: inset 0 1px 0 #F0F0F0;
		}
	}
}

.title {
	font-size: 17px;
	line-height: 26px;
}

.body {
  padding: 24px;
}
