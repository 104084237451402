@import '../../../../../../../assets/styles/variables';

.option {
	display: flex;
	align-items: flex-start;
	max-width: 100%;
	padding: 16px;

	&:hover {
		background-color: $color-blue2;
		cursor: pointer;
	}

	&>*:not(:first-child, :last-child) {
		margin-right: 32px;
	}
}

.icon {
	margin-right: 8px;
}

.number {
	width: 150px;
}

.id > span {
	display: block;
	max-width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
}

// .phone {
//   width: 130px;
// }

.name {
	flex: 1;
	max-width: calc(246px + 130px + 32px);

	span {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.phone,
.name {
	display: flex;
	flex-direction: column;
}

.status {
	display: inline-block;
	width: 160px;
	color: $color-grey1;
}

.button {
	align-self: center;
	margin-left: auto;
	margin-right: 0;
}
