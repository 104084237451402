@import '../../../../../assets/styles/variables';

.tab {
  display: flex;
  align-items: center;
  gap: 5px;

  &_badge {
    font-size: 12px;
    padding: 0 6px;
    color: $color-grey1;
    background-color: $color-grey4;
    border-radius: 100px;

    [aria-selected='true'] & {
      color: inherit;
      background-color: $color-blue2;
    }
  }
}
