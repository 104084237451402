.table {
	padding-left: 24px;
	padding-right: 16px;
	background-color: #fff;
}

.row {
	cursor: pointer;

	&:last-child > td {
		border: none !important;
	}

	& > td {
		vertical-align: top;
		background-color: #fff !important;

		&:first-child {
			padding-left: 0 !important;
		}

		&:last-child {
			padding-right: 0 !important;
		}
	}
}

.info {
	display: flex;
	align-items: center;
	gap: 32px;
}

.actions {
  opacity: 0;
  transition: opacity 0.3s;

  tr:hover & {
    opacity: 1;
  }
}
