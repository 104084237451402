@import '../../../../../../../../../assets/styles/variables';

.wrapper {
	& > button {
		 margin: 24px 0 0 24px;
	}
}

.counters {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 24px;
	margin: 8px 24px 16px;
}

.icon {
	margin-left: 8px;
	cursor: pointer;

	&:hover {
		color: $color-grey1 !important;
	}
}

.button {
	padding: 0;

	&:hover {
		color: $color-blue3 !important;
	}
}

.list {
	display: flex;
	gap: 24px;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.item {
	display: flex;
	align-items: center;
	gap: 8px;
	line-height: 22px;
}

.counter {
	font-size: 17px;
	line-height: 26px;
	color: $color-grey1;

	&__total {
		font-weight: 500;
		font-size: 48px;
		line-height: 56px;

		.counter__current {
			font-size: 64px;
			line-height: 75px;
		}
	}

	&__current {
		font-size: 20px;
		line-height: 28px;
		font-weight: 500;
		color: $color-black;
	}

	&__surplus {
		color: $color-black;
	}

	&__error {
		color: $color-red;
	}
}
