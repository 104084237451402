@import '../../../../assets/styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin: 16px 0;

  &__button {
    padding: 0;
    line-height: 22px;
    border: none;

    &:hover {
      color: $color-blue3 !important;
    }
  }
}

.description {
  font-weight: 500;
  font-size: 17px;
}

.counter {
  font-size: 48px;
  line-height: 56px;
  font-weight: 500;
  color: $color-grey2;

  &__current {
    font-size: 64px;
    line-height: 75px;
    color: $color-black;

    span {
      font-size: 48px;
      line-height: 56px;
      color: $color-red;
    }
  }
}

.modal {
  table {
    thead {
      & * {
        &::before {
          display: none;
        }
      }
    }
    [class="ant-table-cell"] {
      padding: 10px !important;
    }
  }
  [class~="ant-table-cell-row-hover"] {
    background: unset !important;
    padding: 10px !important;
  }
}
